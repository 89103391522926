import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';
import logo from './assets/logo_blacktype.svg';
import globe from './assets/icons/globe.png';
import Menu from './Menu.js';

export default function Navbar() {
    const { t, i18n } = useTranslation();  // Added i18n to use i18n.changeLanguage

    const onMobile = window.innerWidth <= 414;
    const [globeActive, setGlobeActive] = useState(false);
    const openLangPanel = () => {
        setGlobeActive(!globeActive);
    }

    const [menuOpen, setMenuOpen] = useState(false);
    const openMenu = () => {
        setMenuOpen(!menuOpen);
        document.documentElement.style.overflow = menuOpen ? 'auto' : 'hidden';
    }

    // Handle language switching
    const handleChangeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setGlobeActive(false); // Close language dropdown after selection
    }

    return (
        <>
        <div className={styles.navBarContainer}>
        <div className={styles.navBarContent}>

            <div className={styles.logoWrapper}>
                <Link to="/" className={styles.logo} src={logo}></Link>
            </div>

            <div className={`${styles.navWrapper} ${menuOpen ? styles.menuOpen : ''}`}>
                <ul className={styles.navLinks}>
                    <li><Link to="/">{t('navbar.home')}</Link></li>
                    <li><Link to="/coming-soon" href='#'>{t('navbar.flashCards')}</Link></li>

                    <li className={styles.navDropdown}>
                        <Link to="/coming-soon">{t('navbar.levelTest')}</Link>
                        <div className={styles.navDropdownOptionsWrapper}>
                            <ul className={styles.navDropdownOptions}>
                                <li><Link to="/english-test">{t('navbar.english')}</Link></li>
                                <li><Link href='#'>{t('navbar.chinese')}</Link></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>

            <aside className={styles.asideWrapper}>
                <ul className={`${styles.asideLinks} ${menuOpen ? styles.menuOpen : ''}`}>
                    <li className={`${styles.navDropdown} ${styles.signUpLink}`}>
                        <a href='#'>{t('navbar.signUp')}</a>
                        <div className={styles.navDropdownOptionsWrapper}>
                            <ul className={styles.navDropdownOptions}>
                                <li><Link to="/coming-soon">{t('navbar.teacher')}</Link></li>
                                <li><Link to="/sign-up">{t('navbar.student')}</Link></li>
                            </ul>
                        </div>
                    </li>

                    <li className={styles.logInLink}><Link to="/log-in">{t('navbar.logIn')}</Link></li>

                    <li className={styles.navDropdown}>
                        <div className={styles.languageDropdown}>
                            <img className={styles.globe} src={globe} onClick={onMobile ? openLangPanel : () => {}}/>
                            <a href='#'>{t('navbar.language')}</a>
                        </div>

                        <div className={`${onMobile ? styles.languagePanel : styles.navDropdownOptionsWrapper} ${globeActive ? styles.globeActive : ''}`}>
                            <ul className={`${onMobile ? styles.languages : styles.navDropdownOptions}`}>
                                <li><a href='#' onClick={() => handleChangeLanguage('en')}>{t('navbar.english')}</a></li> {/* Added English */}
                                <li><a href='#' onClick={() => handleChangeLanguage('zh')}>{t('navbar.chinese')}</a></li>
                                <li><a href='#' onClick={() => handleChangeLanguage('ru')}>{t('navbar.russian')}</a></li>
                            </ul>
                        </div>
                    </li>                 
                </ul>
            </aside>

            <div 
            className={`${styles.navHamburger} 
            ${menuOpen ? styles.menuOpen : ''}`}
            onClick={openMenu}>
                <div className={styles.navHamburgerBar}></div>
                <div className={styles.navHamburgerBar}></div>
                <div className={styles.navHamburgerBar}></div>
            </div>
        </div>
        </div>

        <Menu active={menuOpen} setActive={openMenu}/>
        </>
    );
}
