import styles from './ComingSoon.module.css';

export default function ComingSoon() {
    return (
        <div className={styles.comingSoonContainer}>
            <div className={styles.comingSoonContent}>
                <h1>This part of the website is being built.</h1>
                <p>Come back later!</p>
            </div>
        </div>
    )
}