import styles from "./LogIn.module.css";
import google from './assets/icons/google.png';
import eyeOpen from './assets/icons/eyeOpen.png';
import eyeClosed from './assets/icons/eyeClosed.png';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function LogIn(props) {
    const { t } = useTranslation();

    // Form state
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // Validation state
    const [formError, setFormError] = useState('');
    const [loginError, setLoginError] = useState('');

    // Password visibility controls
    const [passwordVisible, setPasswordVisible] = useState(false);

    // Toggle visibility function
    const toggleEye = () => setPasswordVisible(prevState => !prevState);

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Mock incorrect credentials for demonstration purposes
    const mockCredentials = {
        email: 'user@example.com',
        password: 'password123'
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        
        setFormError(''); // Reset any general form error
        setLoginError(''); // Reset incorrect login error

        // Validate if all fields are filled out
        if (!email || !password) {
            setFormError(t('SignUp.errors.allFields'));
            return;
        }

        // Validate incorrect email or password
        if (email !== mockCredentials.email || password !== mockCredentials.password) {
            setLoginError(t('SignUp.errors.incorrectLogin'));
            return;
        }

        // If all validations pass, login successfully
        console.log('Login successful!');
        // Proceed with login logic here
    };

    return (
        <div className={styles.SignUpContainer}>
            <div className={styles.SignUpContent}>
                <button className={styles.ContinueWithGoogle}>
                    <img src={google} alt="Google Icon" />
                    <p>{t('SignUp.continueWithGoogle')}</p>
                </button>

                <div className={styles.separationWrapper}>
                    <div className={styles.hrWrapper}><hr /></div>
                    <p>{t('SignUp.or')}</p>
                    <div className={styles.hrWrapper}><hr /></div>
                </div>

                <form onSubmit={handleSubmit} className={styles.formWrapper}>
                    {formError && <p className={styles.error}>{formError}</p>}
                    {loginError && <p className={styles.error}>{loginError}</p>}

                    <div className={styles.fieldWrapper}>
                        <label>{t('SignUp.inputLabels.email')}</label>
                        <div className={styles.inputWrapper}>
                            <input
                                type="email"
                                className={styles.inputField}
                                placeholder={t('SignUp.formPlaceholders.email')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className={styles.fieldWrapper}>
                        <label>{t('SignUp.inputLabels.password')}</label>
                        <div className={styles.inputWrapper}>
                            <input
                                type={passwordVisible ? "text" : "password"}
                                className={styles.inputField}
                                placeholder={t('SignUp.formPlaceholders.password')}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <img
                                src={passwordVisible ? eyeOpen : eyeClosed}
                                className={styles.eye}
                                alt="Toggle password visibility"
                                onClick={toggleEye}
                            />
                        </div>
                    </div>

                    <div className={styles.forgotPasswordWrapper}>
                        <Link to="forgot-password" className={styles.forgotPasswordLink}><p>{t('SignUp.forgotPassword')}</p></Link>
                    </div>

                    <div className={styles.rememberMeWrapper}>
                        <input type="checkbox" />
                        <label>{t('SignUp.rememberMe')}</label>
                    </div>

                    <div className={styles.buttonWrapper}>
                        <button className={styles.registerButton} type="submit">{t('SignUp.logInButton')}</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
