import Start from './Start';
import Courses from './Courses';
import Cases from './Cases'
import Reviews from './Reviews';
import Teachers from './Teachers';

export default function Home() {
    return (
        <>
            <Start />
            <Courses />
            <Cases />
            <Reviews />
            <Teachers />
        </>
    )
}