import { useTranslation } from 'react-i18next';
import styles from './Courses.module.css';

export default function Courses() {
    const { t, i18n } = useTranslation();

    // Example URLs for the PDFs
    const pdfUrls = {
        englishUpgrade: `/materials/pricing/English Upgrade Course Pricing ${i18n.language}.pdf`,
        ieltsIntensive: `/materials/pricing/Intensive IELTS Course Pricing ${i18n.language}.pdf`,
        mandarinChinese: '/materials/pricing/Mandarin Chinese Course Pricing.pdf',
        hskCourse: '/materials/pricing/HSK Course Pricing.pdf',
    };

    const openPdfInNewTab = (url) => {
        window.open(url, '_blank'); // Open the PDF in a new tab
    };

    // Function to get the appropriate font class for the title based on the current language
    const getTitleFontClass = () => {
        switch (i18n.language) {
            case 'ru':
                return styles.titleFontRussian;  // Russian font class
            case 'zh':
                return styles.titleFontChinese;  // Chinese font class
            default:
                return styles.titleFontEnglish;  // Default font class (English)
        }
    };

    return (
        <div className={styles.coursesSection}>
            <div className={styles.coursesSectionContent}> 

                <h1 className={`${styles.coursesSectionTitle} ${getTitleFontClass()}`}>
                    {t('courses.title')}
                </h1>

                <div className={styles.coursesWrapper}>

                    <div className={styles.courseBox + ' ' + styles.course1}>
                        <div className={styles.courseInterfaceWrapper}>
                            <div className={styles.courseInfoWrapper}>
                                <h2 className={`${styles.courseTitle} ${getTitleFontClass()}`}>
                                    {t('courses.englishUpgrade.title')}
                                </h2>
                                <p className={styles.courseSubtitle}>
                                    {t('courses.englishUpgrade.subtitle')}
                                </p>
                            </div>
                            <div className={styles.circleIcon} 
                            onClick={() => openPdfInNewTab(pdfUrls.englishUpgrade)}>
                                <span className={styles.circleText}>
                                    {t('courses.englishUpgrade.moreInfo')}
                                </span>
                                <div className={styles.arrow}></div>  
                            </div>
                        </div>
                    </div>
                    
                    <div className={styles.courseBox + ' ' + styles.course2}>
                        <div className={styles.courseInterfaceWrapper}>

                            <div className={styles.courseInfoWrapper}>
                                <h2 className={`${styles.courseTitle} ${getTitleFontClass()}`}>
                                    {t('courses.ieltsIntensive.title')}
                                </h2>
                                <p className={styles.courseSubtitle}>
                                    {t('courses.ieltsIntensive.subtitle')}
                                </p>
                            </div>

                            <div className={styles.circleIcon}
                            onClick={() => openPdfInNewTab(pdfUrls.ieltsIntensive)}>
                                <span className={styles.circleText}>
                                    {t('courses.ieltsIntensive.moreInfo')}
                                </span>
                                <div className={styles.arrow}></div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.courseBox + ' ' + styles.course3}>
                        <div className={styles.courseInterfaceWrapper}>

                            <div className={styles.courseInfoWrapper}>
                                <h2 className={`${styles.courseTitle} ${getTitleFontClass()}`}>
                                    {t('courses.mandarinChinese.title')}
                                </h2>
                                <p className={styles.courseSubtitle} style={{width: '40%'}}>
                                    {t('courses.mandarinChinese.subtitle')}
                                </p>
                            </div>
                            <div className={styles.circleIcon}
                            onClick={() => openPdfInNewTab(pdfUrls.mandarinChinese)}>
                                <span className={styles.circleText}>
                                    {t('courses.mandarinChinese.moreInfo')}
                                </span>
                                <div className={styles.arrow}></div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.courseBox + ' ' + styles.course4}>
                        <div className={styles.courseInterfaceWrapper}>
                            <div className={styles.courseInfoWrapper}>
                                <h2 className={`${styles.courseTitle} ${getTitleFontClass()}`}>
                                    {t('courses.hskCourse.title')}
                                </h2>
                                <p className={styles.courseSubtitle}>
                                    {t('courses.hskCourse.subtitle')}
                                </p>
                            </div>
                            <div className={styles.circleIcon}
                            onClick={() => openPdfInNewTab(pdfUrls.hskCourse)}>
                                <span className={styles.circleText}>
                                    {t('courses.hskCourse.moreInfo')}
                                </span>
                                <div className={styles.arrow}></div>
                            </div>
                        </div>
                    </div>
                </div>

                <p className={styles.discountInfo}>
                    {t('courses.discountInfo')}
                </p>
            </div>
        </div>
    );
}
