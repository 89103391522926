import styles from "./SignUp.module.css";
import google from './assets/icons/google.png';
import eyeOpen from './assets/icons/eyeOpen.png';
import eyeClosed from './assets/icons/eyeClosed.png';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export default function SignUp(props) {
    const { t } = useTranslation();

    // Form state
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    // Validation state
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [formError, setFormError] = useState('');

    // Password visibility controls
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmationVisible, setConfirmationVisible] = useState(false);

    // Toggle visibility functions
    const toggleEyeOne = () => setPasswordVisible(prevState => !prevState);
    const toggleEyeTwo = () => setConfirmationVisible(prevState => !prevState);

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        let isValid = true;
        setFormError(''); // Reset any general form error
        setEmailError('');
        setPasswordError('');

        // Validate if all fields are filled out
        if (!name || !email || !password || !confirmPassword) {
            setFormError(t('SignUp.errors.allFields'));
            isValid = false;
        }

        // Validate email format
        if (isValid && !emailRegex.test(email)) {
            setEmailError(t('SignUp.errors.emailError'));
            isValid = false;
        }

        // Validate password length and matching
        if (isValid && password.length < 8) {
            setPasswordError(t('SignUp.errors.passwordLength'));
            isValid = false;
        } else if (isValid && password !== confirmPassword) {
            setPasswordError(t('SignUp.errors.passwordsDontMatch'));
            isValid = false;
        }

        // If all validations pass, submit the form
        if (isValid) {
            console.log('Form submitted successfully!');
            // You can call your API or handle form submission logic here
        }
    };

    return (
        <div className={styles.SignUpContainer}>
            <div className={styles.SignUpContent}>
                <button className={styles.ContinueWithGoogle}>
                    <img src={google} alt="Google Icon" />
                    <p>{t('SignUp.continueWithGoogle')}</p>
                </button>

                <div className={styles.separationWrapper}>
                    <div className={styles.hrWrapper}><hr /></div>
                    <p>{t('SignUp.or')}</p>
                    <div className={styles.hrWrapper}><hr /></div>
                </div>

                <form onSubmit={handleSubmit} className={styles.formWrapper}>
                    {formError && <p className={styles.error}>{formError}</p>}

                    <div className={styles.fieldWrapper}>
                        <label>{t('SignUp.inputLabels.name')}</label>
                        <div className={styles.inputWrapper}>
                            <input
                                type="text"
                                className={styles.inputField}
                                placeholder={t('SignUp.formPlaceholders.name')}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className={styles.fieldWrapper}>
                        <label>{t('SignUp.inputLabels.email')}</label>
                        <div className={styles.inputWrapper}>
                            <input
                                type="email"
                                className={styles.inputField}
                                placeholder={t('SignUp.formPlaceholders.email')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        {emailError && <p className={styles.error}>{emailError}</p>}
                    </div>

                    <div className={styles.fieldWrapper}>
                        <label>{t('SignUp.inputLabels.password')}</label>
                        <div className={styles.inputWrapper}>
                            <input
                                type={passwordVisible ? "text" : "password"}
                                className={styles.inputField}
                                placeholder={t('SignUp.formPlaceholders.password')}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <img
                                src={passwordVisible ? eyeOpen : eyeClosed}
                                className={styles.eye}
                                alt="Toggle password visibility"
                                onClick={toggleEyeOne}
                            />
                        </div>
                    </div>

                    <div className={styles.fieldWrapper}>
                        <label>{t('SignUp.inputLabels.confirmPassword')}</label>
                        <div className={styles.inputWrapper}>
                            <input
                                type={confirmationVisible ? "text" : "password"}
                                className={styles.inputField}
                                placeholder={t('SignUp.formPlaceholders.confirmPassword')}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <img
                                src={confirmationVisible ? eyeOpen : eyeClosed}
                                className={styles.eye}
                                alt="Toggle confirm password visibility"
                                onClick={toggleEyeTwo}
                            />
                        </div>
                        {passwordError && <p className={styles.error}>{passwordError}</p>}
                    </div>

                    <div className={styles.rememberMeWrapper}>
                        <input type="checkbox" />
                        <label>{t('SignUp.rememberMe')}</label>
                    </div>

                    <div className={styles.buttonWrapper}>
                        <button className={styles.registerButton} type="submit">{t('SignUp.signUpButton')}</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
