import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Initialize i18next
i18n
  .use(HttpApi)  // Load translations from a backend
  .use(LanguageDetector)  // Detect language from path, localStorage, etc.
  .use(initReactI18next)  // Passes i18n to React components
  .init({
    supportedLngs: ['en', 'ru', 'zh'], // English, Russian, Chinese
    fallbackLng: 'en', // Fallback language if the detected language is not available
    detection: {
      order: ['path', 'localStorage', 'htmlTag'], // Determines language from URL, localStorage, or <html> tag
      caches: ['localStorage'], // Caches language in localStorage for persistence
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Path to translation files
    },
    react: {
      useSuspense: true, // Enable React Suspense for lazy loading translations
    },
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    returnObjects: true,
  });

export default i18n;
