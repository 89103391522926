import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';  // Import BrowserRouter
import App from './App';
import './i18n';  // Import i18n setup

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>  {/* Wrap App in BrowserRouter */}
      <Suspense fallback={<div>Loading translations...</div>}>
        <App />
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);