import styles from "./ForgotPassword.module.css";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function PasswordReset(props) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    // Function to get the appropriate font class for the title based on the current language
    const getTitleFontClass = () => {
        switch (i18n.language) {
            case 'ru':
                return styles.titleFontRussian;  // Russian font class
            case 'zh':
                return styles.titleFontChinese;  // Chinese font class
            case 'en':
                return styles.titleFontEnglish;  // Default font class (English)
        }
    };

    // Form state
    const [email, setEmail] = useState('');

    // Validation state
    const [formError, setFormError] = useState('');

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        
        setFormError(''); // Reset any form error

        // Validate if the email field is filled out
        if (!email) {
            setFormError(t('SignUp.errors.allFields'));
            return;
        }

        // Validate email format
        if (!emailRegex.test(email)) {
            setFormError(t('SignUp.errors.emailError'));
            return;
        }

        // If all validations pass, proceed with the password reset logic
        console.log('Password reset email sent successfully!');
        navigate('/log-in/forgot-password/reset-email-sent'); // Redirect to /email-sent route
        // You can handle the password reset logic here
    };

    return (
        <div className={styles.SignUpContainer}>
            <div className={styles.SignUpContent}>

                <div className={styles.resetTitleWrapper}>
                    <h1 className={styles.resetTitle + ' ' + getTitleFontClass()}>{t('SignUp.resetTitle')}</h1>
                </div>

                <form onSubmit={handleSubmit} className={styles.formWrapper}>
                    {formError && <p className={styles.error}>{formError}</p>}

                    <div className={styles.fieldWrapper}>
                        <label>{t('SignUp.inputLabels.email')}</label>
                        <div className={styles.inputWrapper}>
                            <input
                                type="email"
                                className={styles.inputField}
                                placeholder={t('SignUp.formPlaceholders.email')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className={styles.buttonWrapper}>
                        <button className={styles.registerButton} type="submit">{t('SignUp.submitButton')}</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
