import BackgroundObject from './BackgroundObject';
import styles from './EnglishTest.module.css';

export default function EnglishTest() {
    return (
        <div className={styles.testContainer}>
            <div className={styles.testContent}>

            <div className={styles.bgObjectWrapper}>
                    <BackgroundObject
                        className={styles.bgObjectBall}
                        src={require('./assets/objects/ball.gif')}
                        scrollFactor={0}
                    />
            </div>

            <div className={styles.testTitleWrapper}>
                <h1 className={styles.testTitle}>ENGLISH LEVEL TEST</h1>
            </div>

            <div className={styles.testFeaturesWrapper}>
                <div className={styles.testFeature}>±30 minutes</div>
                <div className={styles.testFeature}>50 questions</div>
            </div>

            <div className={styles.testStartButtonWrapper}>
                        <button className={styles.testStartButton}>Start</button>
            </div>


            </div>
        </div>
    )
}