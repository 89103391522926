import { useState } from 'react';
import styles from './Menu.module.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Menu({ active, setActive }) {
    const { t } = useTranslation(); // Initialize translation hook

    // Use an object to track which dropdown is open
    const [dropdownOpen, setDropdownOpen] = useState({});

    // Function to toggle a specific dropdown
    const toggleDropdown = (dropdownKey) => (event) => {
        event.preventDefault(); // Prevent the default anchor behavior
        // Toggle the specific dropdown key
        setDropdownOpen((prev) => ({
            ...prev,
            [dropdownKey]: !prev[dropdownKey],
        }));
    };

    if (active) {
        return (
            <div className={styles.menuContainer}>
                <div className={styles.menuContent}>
                    <div className={styles.menuNavWrapper}>
                        <ul className={styles.menuNav}>
                            <li>
                                <Link to="/coming-soon" onClick={setActive} className={styles.menuNavLink}>
                                    {t('menu.home')}
                                </Link>
                            </li>
                            <li>
                                <Link to="/coming-soon" onClick={setActive} className={styles.menuNavLink}>
                                    {t('menu.flashCards')}
                                </Link>
                            </li>

                            {/* First Dropdown */}
                            <li className={`${styles.menuNavDropdown} ${dropdownOpen['levelTest'] ? styles.open : ''}`}>
                                <a
                                    className={styles.menuNavLink}
                                    href='#'
                                    onClick={toggleDropdown('levelTest')} // Unique key for this dropdown
                                >
                                    {t('menu.levelTest')}
                                </a>

                                {/* Dropdown options */}
                                <div
                                    className={`${styles.menuNavDropdownOptionsWrapper} ${dropdownOpen['levelTest'] ? styles.open : ''}`}
                                >
                                    <ul className={styles.menuNavDropdownOptions}>
                                        <li>
                                            <Link to="/english-test" onClick={setActive}>{t('menu.english')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/coming-soon" onClick={setActive}>{t('menu.chinese')}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className={styles.menuButtons}>
                        <Link to="/log-in" onClick={setActive} className={styles.logInButton}>
                            {t('menu.logIn')}
                        </Link>
                        <Link to="/sign-up" onClick={setActive} className={styles.signUpButton}>
                            {t('menu.signUp')}
                        </Link>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}
