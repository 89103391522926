import styles from "./ResetEmailSent.module.css";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function PasswordReset(props) {
    const { t, i18n } = useTranslation();

    // Function to get the appropriate font class for the title based on the current language
    const getTitleFontClass = () => {
        switch (i18n.language) {
            case 'ru':
                return styles.titleFontRussian;  // Russian font class
            case 'zh':
                return styles.titleFontChinese;  // Chinese font class
            case 'en':
                return styles.titleFontEnglish;  // Default font class (English)
        }
    };

    return (
        <div className={styles.emailSentContainer}>
            <div className={styles.emailSentContent}>

                <div className={styles.emailSentIcon}></div>

                <div className={styles.emailTitleWrapper}>
                    <h1 className={styles.emailTitle + ' ' + getTitleFontClass()}>{t('SignUp.emailSentTitle')}</h1>
                </div>

                <div className={styles.emailParagraphWrapper}>
                    <p className={styles.emailParagraph}>{t('SignUp.emailSentParagraph')}</p>
                </div>

            </div>
        </div>
    );
}
