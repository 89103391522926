import styles from "./PasswordReset.module.css";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import eyeOpen from './assets/icons/eyeOpen.png';
import eyeClosed from './assets/icons/eyeClosed.png';

export default function PasswordReset(props) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    // Function to get the appropriate font class for the title based on the current language
    const getTitleFontClass = () => {
        switch (i18n.language) {
            case 'ru':
                return styles.titleFontRussian;  // Russian font class
            case 'zh':
                return styles.titleFontChinese;  // Chinese font class
            case 'en':
                return styles.titleFontEnglish;  // Default font class (English)
        }
    };

    // Validation state
    const [formError, setFormError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    // Password visibility controls
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmationVisible, setConfirmationVisible] = useState(false);

    // Toggle visibility functions
    const toggleEyeOne = () => setPasswordVisible(prevState => !prevState);
    const toggleEyeTwo = () => setConfirmationVisible(prevState => !prevState);

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        
        let isValid = true;
        setFormError(''); // Reset any general form error
        setPasswordError('');

        // Validate if all fields are filled out
        if (!password || !confirmPassword) {
            setFormError(t('SignUp.errors.allFields'));
            isValid = false;
        }

        // Validate password length and matching
        if (isValid && password.length < 8) {
            setPasswordError(t('SignUp.errors.passwordLength'));
            isValid = false;
        } else if (isValid && password !== confirmPassword) {
            setPasswordError(t('SignUp.errors.passwordsDontMatch'));
            isValid = false;
        }

        // If all validations pass, submit the form
        if (isValid) {
            console.log('Form submitted successfully!');
            // You can call your API or handle form submission logic here
        }

    };

    return (
        <div className={styles.SignUpContainer}>
            <div className={styles.SignUpContent}>

                <div className={styles.resetTitleWrapper}>
                    <h1 className={styles.resetTitle + ' ' + getTitleFontClass()}>{t('SignUp.resetTitle')}</h1>
                </div>

                <form onSubmit={handleSubmit} className={styles.formWrapper}>
                    {formError && <p className={styles.error}>{formError}</p>}

                    <div className={styles.fieldWrapper}>
                        <label>{t('SignUp.newPassword')}</label>
                        <div className={styles.inputWrapper}>
                            <input
                                type={passwordVisible ? "text" : "password"}
                                className={styles.inputField}
                                placeholder={t('SignUp.yourNewPassword')}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <img
                                src={passwordVisible ? eyeOpen : eyeClosed}
                                className={styles.eye}
                                alt="Toggle password visibility"
                                onClick={toggleEyeOne}
                            />
                        </div>
                    </div>

                    <div className={styles.fieldWrapper}>
                        <label>{t('SignUp.inputLabels.confirmPassword')}</label>
                        <div className={styles.inputWrapper}>
                            <input
                                type={confirmationVisible ? "text" : "password"}
                                className={styles.inputField}
                                placeholder={t('SignUp.formPlaceholders.confirmPassword')}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <img
                                src={confirmationVisible ? eyeOpen : eyeClosed}
                                className={styles.eye}
                                alt="Toggle confirm password visibility"
                                onClick={toggleEyeTwo}
                            />
                        </div>
                        {passwordError && <p className={styles.error}>{passwordError}</p>}
                    </div>

                    <div className={styles.buttonWrapper}>
                        <button className={styles.registerButton} type="submit">{t('SignUp.submitButton')}</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
