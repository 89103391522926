import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Start.module.css';
import faces from './assets/faces.gif';
import callInterface from './assets/callInterface.png';
import FreeLessonPopup from './FreeLessonPopup';

export default function Start() {
    const { t, i18n } = useTranslation();

    // Handling the Pop-up Window
    const [showPopup, setShowPopup] = useState(false);
    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    // Function to get the appropriate font class for the title based on the current language
    const getTitleFontClass = () => {
        switch (i18n.language) {
            case 'ru':
                return styles.titleFontRussian;  // Russian font class
            case 'zh':
                return styles.titleFontChinese;  // Chinese font class
            case 'en':
                return styles.titleFontEnglish;  // Default font class (English)
        }
    };

    return (
        <section className={styles.startSection}>
            <div className={styles.startSectionContent}>
                <div className={styles.left}>
                    <div className={styles.regalia}>
                        <div><p>{t('start.studentsInCountries')}</p></div>
                        <div><p>{t('start.experiencedTeachers')}</p></div>
                        <div><p>{t('start.digitalEcosystem')}</p></div>
                    </div>

                    {/* Apply dynamic font class to the h1 element */}
                    <div className={styles.title}>
                        <h1 className={getTitleFontClass()}>{t('start.title')}</h1>
                    </div>

                    <div className={styles.bulletPoints}>
                        <ul>
                            <li>{t('start.freeLesson')}</li>
                            <li>{t('start.breakLanguageBarrier')}</li>
                            <li>{t('start.personalizedPlan')}</li>
                        </ul>
                    </div>
                </div>

                <div className={styles.right}>
                    <div className={styles.gifWrapper}>
                        <img className={styles.facesGif} src={faces} alt="Faces" />
                        <img className={styles.callInterface} src={callInterface} alt="CallInterface" />
                    </div>

                    <div className={styles.buttonContainer}>
                        <button onClick={togglePopup}>{t('start.freeLessonButton')}</button>
                    </div>
                </div>

                <FreeLessonPopup showPopup={showPopup} togglePopup={togglePopup} />
            </div>
        </section>
    );
}
