import { useTranslation } from 'react-i18next'; // Importing useTranslation
import styles from './Reviews.module.css';
import BackgroundObject from './BackgroundObject';

export default function Reviews() {
    const { t, i18n } = useTranslation(); // Initialize the translation hook

    // Function to get the appropriate font class for the case based on the current language
    const getFontClass = () => {
        switch (i18n.language) {
            case 'ru':
                return styles.titleFontRussian;  // Russian font class
            case 'zh':
                return styles.titleFontChinese;  // Chinese font class
            default:
                return styles.titleFontEnglish;  // Default font class (English)
        }
    };

    return (
        <div className={styles.reviewsSectionContainer}>
            <div className={styles.reviewsSectionContent}>

                <div className={styles.bgObjectWrapper}>
                    <BackgroundObject
                        className={styles.bgObjectSonic} 
                        src={require('./assets/objects/sonic.png')} 
                        scrollFactor={-0.03}
                    />
                </div>

                <div className={styles.bgObjectWrapper}>
                    <BackgroundObject
                        className={styles.bgObjectPasta} 
                        src={require('./assets/objects/pasta.png')} 
                        scrollFactor={-0.02}
                    />
                </div>

                <h1 className={styles.reviewsSectionTitle + ' ' + getFontClass()}>{t('reviews.title')}</h1>
                
                <div className={styles.reviewsGrid}>
                    <div className={styles.reviewsLeft}>
                        <div className={styles.reviewsLeftTop}>

                            <div className={styles.speechBubble + ' ' + styles.reviewPercentage}>
                                <h2>{t('reviews.reviewPercentage.title')}</h2>
                                <div className={styles.starsWrapper}>
                                    <div className={styles.bigStar}></div>
                                    <div className={styles.littleStar}></div>
                                </div>
                                <p>{t('reviews.reviewPercentage.text')}</p>
                            </div>

                            <div className={styles.speechBubble + ' ' + styles.review1}>
                                <p>{t('reviews.review1')}</p>
                            </div>
                        </div>

                        <div className={styles.reviewsLeftBottom}>
                            <div className={styles.speechBubble + ' ' + styles.review2}>
                                <p>{t('reviews.review2')}</p>
                            </div>

                            <div className={styles.speechBubble + ' ' + styles.review3}>
                                <p>{t('reviews.review3')}</p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.reviewsRight}>
                        <div className={styles.speechBubble + ' ' + styles.review4}>
                            <p>{t('reviews.review4')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
