import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import translation hook
import styles from './FreeLessonPopup.module.css'; 
import sign from './assets/sign.svg';

export default function FreeLessonPopup({ showPopup, togglePopup }) {
    const { t, i18n } = useTranslation(); // Use the translation function

    // Function to get the appropriate font class for the case based on the current language
    const getFontClass = () => {
        switch (i18n.language) {
            case 'ru':
                return styles.titleFontRussian;  // Russian font class
            case 'zh':
                return styles.titleFontChinese;  // Chinese font class
            default:
                return styles.titleFontEnglish;  // Default font class (English)
        }
    };

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: ''
    });

    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const validatePhone = (phone) => {
        const phonePattern = /^[+][0-9]{1,3}[0-9\s]{7,15}$/;
        return phonePattern.test(phone);
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) {
            newErrors.name = t('freeLessonPopup.errors.name');
        }
        if (!formData.phone) {
            newErrors.phone = t('freeLessonPopup.errors.phone');
        } else if (!validatePhone(formData.phone)) {
            newErrors.phone = t('freeLessonPopup.errors.phoneInvalid');
        }
        if (!formData.email) {
            newErrors.email = t('freeLessonPopup.errors.email');
        } else if (!validateEmail(formData.email)) {
            newErrors.email = t('freeLessonPopup.errors.emailInvalid');
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; 
    };

    const handleSubmit = (e) => {
        e.preventDefault(); 

        if (!validateForm()) {
            return; 
        }

        setLoading(true); 

        fetch('https://chase-fluency-webapp.onrender.com/submit-form', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then(response => response.json())
        .then(() => {
            setShowSuccessPopup(true);  
            setLoading(false);  

            setTimeout(() => {
                setShowSuccessPopup(false);
                togglePopup();  
            }, 3000);

            setFormData({ name: '', phone: '', email: '' });
        })
        .catch((error) => {
            console.error('Error:', error);
            setLoading(false);  
        });
    };

    return (
        <>
            <div className={`${styles.overlay} ${showPopup ? styles.overlayVisible : ''}`}></div>
            <div className={`${styles.popup} ${showPopup ? styles.popupVisible : ''}`}>
                <div className={styles.closeIcon} onClick={togglePopup}>&times;</div>
                
                <div className={styles.popupContent}>
                    <div className={styles.popupTop}>
                        <h2 className={styles.popupTitle + ' ' + getFontClass()}>
                            {t('freeLessonPopup.title')}
                        </h2>
                        <img src={sign} className={styles.popupSign} alt="Sign" />
                    </div>

                    <div className={styles.popupMain}>
                        <div className={styles.popupLeftSide}>    
                            <div className={styles.steps}>
                                <div className={styles.stepItem}>
                                    <span className={styles.circle}></span>
                                    <p>{t('freeLessonPopup.steps.step1')}</p>
                                </div>

                                <div className={styles.stepItem}>
                                    <span className={styles.circle}></span>
                                    <p>{t('freeLessonPopup.steps.step2')}</p>
                                    <div className={styles.lineTop}></div>
                                </div>

                                <div className={styles.stepItem}>
                                    <span className={styles.circle}></span>
                                    <p>{t('freeLessonPopup.steps.step3')}</p>
                                    <div className={styles.lineTop}></div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.formSection}>
                            <form className={styles.form} onSubmit={handleSubmit}>
                                <div className={styles.inputWrapper}>
                                    <p className={`${styles.error} ${errors.name ? styles.errorVisible : ''}`}>
                                        {errors.name || ''}
                                    </p>
                                    <input 
                                        type="text" 
                                        name="name" 
                                        placeholder={t('freeLessonPopup.form.namePlaceholder')} 
                                        className={styles.inputField} 
                                        value={formData.name} 
                                        onChange={handleInputChange} 
                                    />
                                </div>

                                <div className={styles.inputWrapper}>
                                    <p className={`${styles.error} ${errors.phone ? styles.errorVisible : ''}`}>
                                        {errors.phone || ''}
                                    </p>
                                    <input 
                                        type="tel" 
                                        name="phone" 
                                        placeholder={t('freeLessonPopup.form.phonePlaceholder')} 
                                        className={styles.inputField} 
                                        value={formData.phone} 
                                        onChange={handleInputChange} 
                                    />
                                </div>

                                <div className={styles.inputWrapper}>
                                    <p className={`${styles.error} ${errors.email ? styles.errorVisible : ''}`}>
                                        {errors.email || ''}
                                    </p>
                                    <input 
                                        type="email" 
                                        name="email" 
                                        placeholder={t('freeLessonPopup.form.emailPlaceholder')} 
                                        className={styles.inputField} 
                                        value={formData.email} 
                                        onChange={handleInputChange} 
                                    />
                                </div>

                                <button type="submit" className={styles.submitButton}>
                                    {loading ? (
                                        <div className={styles.loader}></div> 
                                    ) : (
                                        t('freeLessonPopup.form.submitButton') 
                                    )}
                                </button>
                            </form>
                        </div>
                    </div> 
                </div>
            </div>

            <div className={`${styles.successPopup} ${showSuccessPopup ? styles.successPopupVisible : ''}`}>
                {t('freeLessonPopup.successMessage')}
            </div>
        </>
    );
}
