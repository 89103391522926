import { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Importing useTranslation hook
import styles from './Cases.module.css';
import BackgroundObject from './BackgroundObject';

export default function Cases() {
    const { t, i18n } = useTranslation(); // Initialize the translation hook

    // Function to get the appropriate font class for the case based on the current language
    const getFontClass = () => {
        switch (i18n.language) {
            case 'ru':
                return styles.titleFontRussian;  // Russian font class
            case 'zh':
                return styles.titleFontChinese;  // Chinese font class
            default:
                return styles.titleFontEnglish;  // Default font class (English)
        }
    };

    const cases = [
        {
            photo: './assets/students/JamilPhoto.jpg',
            photoPosition: '-90px -220px',
            photoSize: '35vw',
            certificate: './assets/students/JamilCertificate.png',
            name: t('cases.students.jamil.name'), // Translated name
            bio: t('cases.students.jamil.bio'),   // Translated bio
            highlights: t('cases.students.jamil.highlights', { returnObjects: true }) // Translated highlights
        },
        {
            photo: './assets/students/RuePhoto.jpg',
            photoSize: '30vw',
            photoPosition: '-50px -80px',
            certificate: './assets/students/RueCertificate.png',
            name: t('cases.students.rueLin.name'),  // Translated name
            bio: t('cases.students.rueLin.bio'),    // Translated bio
            highlights: t('cases.students.rueLin.highlights', { returnObjects: true }) // Translated highlights
        }
    ];

    const [caseIndex, setCaseIndex] = useState(0);

    // Defining event handlers
    const goBack = () => setCaseIndex(prevCaseIndex => prevCaseIndex - 1);
    const goForward = () => setCaseIndex(prevCaseIndex => prevCaseIndex + 1);

    // See if it's the first or the last index
    const isFirstCase = caseIndex === 0;
    const isLastCase = caseIndex === cases.length - 1;

    return (
        <div className={styles.casesSectionContainer}>
            <div className={styles.casesSectionContent}>

                <div className={styles.bgObjectWrapper}>
                    <BackgroundObject
                        className={styles.bgObjectStar} 
                        src={require('./assets/objects/star.png')} 
                        scrollFactor={-0.01}
                    />
                </div>

                <div className={styles.bgObjectWrapper}>
                    <BackgroundObject
                        className={styles.bgObjectPin} 
                        src={require('./assets/objects/pin.png')} 
                        scrollFactor={-0.01}
                    />
                </div>

                <div className={styles.bgObjectWrapper}>
                    <BackgroundObject 
                        className={styles.bgObjectSpiral} 
                        src={require('./assets/objects/spiral.png')} 
                        scrollFactor={-0.01}
                    />
                </div>

                <div className={styles.casesTitleWrapper}>
                    <h1 className={styles.casesTitle + ' ' + getFontClass()}>{t('cases.title')}</h1>
                </div>

                <div className={styles.caseBox}>
                    <div className={styles.caseBoxContent}>
                        <div className={styles.caseImgWrapper}>
                            <div className={styles.caseImg + ' ' + styles.casePhoto}
                            style={{
                                    backgroundImage: `url(${cases[caseIndex].photo})`,
                                    backgroundPosition: cases[caseIndex].photoPosition,
                                    backgroundSize: cases[caseIndex].photoSize
                                    }}>
                            </div>

                            <div className={styles.caseImg + ' ' + styles.caseCertificate}
                            style={{backgroundImage: `url(${cases[caseIndex].certificate})`,}}>
                            </div>
                        </div>

                        <div className={styles.caseInfoWrapper}>
                            {/* Apply dynamic font class to the name and bio */}
                            <h2 className={styles.caseName}>
                                {cases[caseIndex].name}
                            </h2>
                            <p className={styles.caseBio}>
                                {cases[caseIndex].bio}
                            </p>
                            
                            <div className={styles.caseHighlightsWrapper}>
                                {cases[caseIndex].highlights.map((item, index) => (
                                    <div key={index} className={styles.caseHighlight}>
                                        {item}
                                    </div>
                                ))}
                            </div>

                            <div className={styles.caseControls}>
                                <button 
                                onClick={goBack}
                                disabled={isFirstCase}
                                className={styles.caseButtonBack}
                                style={{backgroundColor: '#DBDBDB'}}
                                > 
                                    <div className={styles.arrowLeft}></div>
                                </button>
                                
                                <button 
                                onClick={goForward}
                                disabled={isLastCase}
                                className={styles.caseButtonForward}
                                style={{backgroundColor: '#DBDBDB'}}
                                >
                                    <div className={styles.arrowRight}></div>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
